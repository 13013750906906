/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
import { graphql, Link, useStaticQuery } from "gatsby"
import * as React from "react"
import Container from "components/container"
import { H3, H5, P } from "components/headings"
import styled from "@emotion/styled"
import { IColorScheme, theme } from "common/colorScheme"
import { useMemo, useState } from "react"
import { NumberParam, useQueryParam } from "use-query-params"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"
import { css } from "@emotion/react"
import qs from "query-string"
import { IJsonContentfulField } from "common/types"
import { getCustomStyle } from "components/sections/utils"
import Dropdown from "components/Dropdown"
import { ButtonBase } from "components/button"
import { MediaProps } from "components/Media"
import ArticlesForm from "components/Form/ArticlesForm"
import InstagramEmbed from "components/InstagramEmbed"
import SearchInput from "components/SearchInput"
import { PageReference } from "./types"
import FeaturedReference from "./FeaturedReference"
import CloseBtn from "./static/close-button.svg"
import Reference from "./Reference"


type TitleAlign = "default" | "center"

export type ReferencesStyle = "default" | "card"

export type FeaturedStyle = "default" | "brand-bites"

export interface IBrandImages {
	logos?: MediaProps[]
	heading?: {
		heading: string
	}
}

export const formQuery = graphql`
query {
	contentfulForm(formId: { eq: "newsletter" }) {
		...Form
	}
}
`

interface ITag {
	name: string
	color: string
	textColor: string
}
interface IReferencesSectionProps {
	featuredTitle: {
		featuredTitle: string
	}
	featuredSubtitle: string
	featuredButtonText: string
	featuredReference?: PageReference
	featuredStyle?: FeaturedStyle
	featuredColorScheme?: IColorScheme
	title?: {
		title: string
	}
	titleAlign?: TitleAlign
	description?: {
		description: string
	}
	references?: PageReference[]
	referencesStyle?: ReferencesStyle
	customSpacing: IJsonContentfulField
	embedYoutubeId: string
	hideFiltersWrapper: boolean
	hideNewsletterForm: boolean
	brandImages: IBrandImages
	brandImagesScrolling: boolean
	searchInput: boolean
	anchor: string
}
interface IFilterProps {
	active?: boolean
	color?: string
	textColor?: string
}

const Section = styled.section`
	padding: 80px 0;
`

export const FiltersContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: left;
	flex-wrap: wrap;
	margin-top: ${theme.spacing(4.25)};
	margin-bottom: ${theme.spacing(4.25)};
	margin-left:-1rem;
`

export const Filter = styled.div<IFilterProps>`
	display: flex;
	justify-content: center;
	cursor: pointer;
	width: fit-content;
	font-size: 1.1rem;
	line-height: 2.4rem;
	padding: 8px 20px;
	text-transform: uppercase;
	font-weight: 800;
	color: ${({ color, textColor, active }) =>
		active && !color
			? theme.color.white
			: !active && !color
				? theme.color.darkPurple
				: active && textColor
					? textColor
					: textColor};
	background-color: ${({ color, active }) =>
		active && !color
			? theme.color.darkPurple
			: !active && !color
				? theme.color.lightPurple
				: active && color
					? `${color}`
					: `${color}`};
	border-radius: 19px;
	height: 40px;
	margin: 8px;
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1);
	user-select: none;

	&:hover {
		background-color: ${({ textColor, color }) => (color ? `${textColor}` : "#d3c8ff")} !important;
		color: ${({ color }) => color && `#fff`} !important;
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		margin: 12px;
		padding: 8px 28px;
		min-width: 146px;
		font-size: 1.2rem;
	}
`

const LastButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 8px;
	${mediaBreakpoint(BreakPoints.MD)} {
		width: fit-content;
		margin: 12px;
	}

	${Filter} {
		padding: ${theme.spacing(1, 1.1)};
		margin: 0;
		min-width: initial;

		&:active {
			background-color: ${theme.color.darkPurple};
		}
	}
`
const FilterImage = styled.img`
	padding: 5px;
`
const ReferencesContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(284px, 1fr));
	margin: 40px -12px 0;

	${mediaBreakpoint(BreakPoints.MD)} {
		grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
	}
`

const NoResultContainer = styled.div`
	text-align: center;
	padding: 30px;
`
const FiltersWrapper = styled.div`
		width: initial;
		display:content;		
	${mediaBreakpoint(BreakPoints.MD)} {
		display:flex;
		width:100%;
	}
`
const SelectDropdownContainer = styled.div`
	width: 100%;
	${mediaBreakpoint(BreakPoints.MD)} {
		width: 20%;
	}
`

const ReferenceContainer = styled(Container)`
	display: flex;
	flex-flow: row wrap;
`

const ReferencesWrapper = styled.div`
	width: 100%;
`
const loadMoreLinkStyles = css`
	width: 100%;
	text-decoration: none;
	padding-bottom: 3rem;
`
const loadMoreStyles = css`
	display: block;
	width: initial;
	margin: 12px auto;
	min-width: 192px;
`

const TitleWrapper = styled.div<{ searchInput: boolean; titleAlign: string }>`
	padding:0;
	width: 100%;
	display: flex;
    justify-content: ${({ searchInput, titleAlign }) => searchInput ? 'space-between' : titleAlign === 'center' ? 'center' : 'flex-start' };
`

const Title = styled.div``

const DesktopInputContainer = styled.div`
	width: 100%;
	max-width: 380px;
	display: none;

	${mediaBreakpoint(BreakPoints.MD)} {
		display: block;
	}
`

const ReferencesSection: React.FC<IReferencesSectionProps> = ({
	featuredSubtitle,
	featuredTitle,
	featuredButtonText,
	featuredReference,
	featuredStyle,
	featuredColorScheme,
	references = [],
	title,
	titleAlign,
	description,
	referencesStyle,
	customSpacing,
	embedYoutubeId,
	hideFiltersWrapper,
	hideNewsletterForm,
	brandImages,
	brandImagesScrolling,
	searchInput,
	anchor
}) => {
	const { contentfulForm } = useStaticQuery(formQuery)
	const [ pageParam = 1, setPageParam ] = useQueryParam("page", NumberParam)
	const [ filters, setFilters ] = useState<string[]>([])
	const [ sortBy, setSortBy ] = useState<String>()
	const [searchParam, setSearchParam] = useState<String>('')

	const onHandleSearch = e => {
		setSearchParam(e.target.value)
	}

	const options = [
		{
			id:'most_recent',
			label: 'MOST RECENT'
		}, 
		{
			id:'most_popuar',
			label: 'MOST POPULAR'
		},
		{
			id:'a-z',
			label: 'A-Z'
		},
		{
			id:'z-a',
			label: 'Z-A'
		}]
	const filterActive = (filterName: string) => filters.indexOf(filterName) > -1
	const perPage = 12
	const handleFilterClearClick = () => setFilters([])
	const handleFilterClick = (filterName: string) => {
		let newFilters = [...filters]
		if (filterActive(filterName)) {
			const newArr = newFilters.splice(filters.indexOf(filterName), 1)
			setFilters(newArr)
		} else {
			newFilters = [filterName]
		}
		setFilters(newFilters)
	}
	const normalizeTab = (name: string) => name.replace(" ", "-").toLowerCase()

	const generateTagObject = (tag: ITag) => ({
		label: tag.name,
		value: normalizeTab(tag.name),
		color: tag.color,
		textColor: tag.textColor,
	})

	const getReferences = (filtersList, referenenceList, sort) => {
		const results = filtersList.length === 0
			? referenenceList
			: referenenceList.filter(reference =>
				filtersList.every(filter => reference.filterCategories?.some(filterCategory => filterCategory === filter))
			)
		switch (sort) {
		case "most_recent":
			return results.sort((a:any, b:any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
		case "most_popuar":
			return results.sort((a:any) => a.popular ? -1: 1)
		case "a-z":
			return results.sort((a:any, b:any) => a.referenceTitle !== b.referenceTitle ? a.referenceTitle < b.referenceTitle ? -1 : 1 : 0)
		case "z-a":
			return results.sort((a:any, b:any) => a.referenceTitle !== b.referenceTitle ? b.referenceTitle < a.referenceTitle ? -1 : 1 : 0)
		default:
			return results
		}
	}

	const Tags = [
		{ name: "ecommerce", color: "#C2E1FB", textColor: "#115187" },
		{ name: "video", color: "#D2F6D5", textColor: "#03660B" },
		{ name: "health & wellness", color: "#C2E1FB", textColor: "#115187" },
		{ name: "food delivery", color: "#FFD1C4", textColor: "#74220B" },
		{ name: "news & media", color: "#FFD3F1", textColor: "#740952" },
		{ name: "Apparel", color: "#E8D7FF", textColor: "#5f4180" },
		{ name: "fitness", color: "#FFE6BA", textColor: "#81570D" },
		{ name: "beauty", color: "#B4ECE9", textColor: "#024D49" },
		{ name: "entertainment", color: "#FFCEDB", textColor: "#840B2A" },
		{ name: "dating apps", color: "#DAFAC7", textColor: "#255F04" },
		{ name: "fashion", color: "#FFD3F1", textColor: "#740952" },
		{ name: "tech", color: "#FFD1C4", textColor: "#74220B" },
		{ name: "food & drink", color: "#FFE6BA", textColor: "#81570D" },
		{ name: "ultimate guide", color: "#EBE6FF", textColor: "#31135E" },
		{ name: "level up guide", color: "#B4ECE9", textColor: "#005C58" },
		{ name: "branding 101", color: "#D2F6D5", textColor: "#005B08" },
		{ name: "brand marketing guide", color: "#FFCEDB", textColor: "#840B2A" },
		{ name: "speaking to series", color: "#FFD3F1", textColor: "#840B2A" },
		{ name: "finance", color: "#FFE6BA", textColor: "#81570D" },
		{ name: "home", color: "#DAFAC7", textColor: "#255F04" },
		{ name: "e-learning", color: "#C2E1FB", textColor: "#00324e" },
		{ name: "e-bikes", color: "#DFE2FF", textColor: "#31135E" },
		{ name: "fintech", color: "#FFE6BA", textColor: "#81570D" },
		{ name: "Brand Awareness", color: "#DAFAC7", textColor: "#255F04" },
		{ name: "Brand Strategy", color: "#EBE6FF", textColor: "#31135E" },
		{ name: "Brand Sustainability", color: "#C2E1FB", textColor: "#115187" },
		{ name: "Brand Marketing", color: "#B4ECE9", textColor: "#024D49" },
		{ name: "Brand Deep Dives", color: "#FFD3F1", textColor: "#740952" },
		{ name: "Brand Tracking", color: "#FFD1C4", textColor: "#74220B" },
		{ name: "Consumer Insights", color: "#FFE6BA", textColor: "#81570D" },
		{ name: "Work Advice", color: "#DAFAC7", textColor: "#255F04" },
		{ name: "NewsFlash", color: "#D3D3D3", textColor: "#202020" },
		{ name: "article", color: "#FFD1C4", textColor: "#740952" }

	].map(generateTagObject)

	const generateTagObjectFromString = (tag: string) => ({
		label: tag,
		value: normalizeTab(tag),
		color: Tags.filter(elem => elem.label === tag)[0]?.color || "#EBE6FF",
		textColor: Tags.filter(elem => elem.label === tag)[0]?.textColor || "#5f4180",
	})

	const getColor = (tag: string) => {
		return Tags.filter(elem => elem.label === tag)[0]?.color || "#EBE6FF"
	}

	const getTextColor = (tag: string) => {
		return Tags.filter(elem => elem.label === tag)[0]?.textColor || "#5f4180"
	}

	const filteredReferences = useMemo(() => getReferences(filters, references,  sortBy),[filters, references, sortBy])
		
	const referencesToRender = useMemo(() => {
		return searchInput ? (filteredReferences || []).filter(({ longTitle }) => longTitle.title.toLowerCase().includes(searchParam.toLowerCase())) : filteredReferences
	},[searchParam, filters, references, sortBy])
		
	const filtersFromReferences = useMemo(() => {
		let uniqueItems = []

		if (references) {
			const filtersArr = references
				// filter out references without categories
				.filter(reference => reference.filterCategories !== null)
				.map(reference => reference.filterCategories)
			const flattenedArr = [].concat(...filtersArr)
			uniqueItems = flattenedArr.filter((x, i, a) => a.indexOf(x) === i)
		}

		return uniqueItems
	}, [references])

	const maxPages = React.useMemo(() => references ? Math.ceil(references.length / perPage ) : 0, [references])
	const page = React.useMemo(() => {
		let pageNumber = pageParam || 1
		if (Number.isNaN(pageNumber) || pageNumber < 1) {
			pageNumber = 1
		}
		if (pageNumber > maxPages) {
			pageNumber = maxPages
		}
		return pageNumber
	}, [maxPages, pageParam])


	const displayReferences = React.useMemo(() => {
		return (referencesToRender || []).slice(0, page * perPage)
	}, [referencesToRender, page, sortBy])


	const displayReferencesTop = React.useMemo(() => {
		return displayReferences.slice(0,12)
	}, [displayReferences, sortBy])


	const displayReferencesBottom = React.useMemo(() => {
		return displayReferences.slice(12)
	}, [displayReferences, sortBy])

	
	const hasMore = page < maxPages


	const nextPage = page + 1


	// for SEO
	const nextPageLink = React.useMemo(() => {
		return `/?${qs.stringify({
			page: nextPage
		})}`
	}, [nextPage])

	const handleMore = React.useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault()
			setPageParam(page + 1)
		},
		[nextPageLink]
	)

	const updateDropdown = (item: String) => {
		setSortBy(item)
	}

	return (
		<div css={[getCustomStyle(customSpacing)]}>
			{featuredReference && featuredTitle && featuredButtonText && (
				<FeaturedReference
					buttonText={featuredButtonText}
					title={featuredTitle.featuredTitle}
					reference={featuredReference}
					featuredStyle={featuredStyle}
					featuredColorScheme={featuredColorScheme}
					featuredSubtitle={featuredSubtitle}
					embedYoutubeId = {embedYoutubeId}
					brandImages={brandImages}
					brandImagesScrolling= {brandImagesScrolling}
				/>	
			)}
			{referencesToRender && (
				<Section data-element="section" id={anchor}>
					<ReferenceContainer data-element="container">
						<TitleWrapper searchInput={searchInput} titleAlign={titleAlign}>
							<Title>
								{title ? <H3 data-element="title">{title.title}</H3> : null}
								{description ? <P>{description.description}</P> : null}
							</Title>
							{searchInput && (<DesktopInputContainer>
								<SearchInput type="text" placeholder="Search" searchParam={searchParam} onHandleSearch={onHandleSearch} />
							</DesktopInputContainer>)}
						</TitleWrapper>
						{!hideFiltersWrapper ?
							<FiltersWrapper>
							
								{filtersFromReferences?.length > 0 && (
									<FiltersContainer>
										{(filtersFromReferences || []).map(filter => (
											<Filter
												key={filter}
												role="button"
												onMouseDown={() => handleFilterClick(filter)}
												active={filterActive(filter)}
												color={getColor(filter)}
												textColor={getTextColor(filter)}
											>
												{filter}
											</Filter>
										))}
										<LastButtonContainer>
											<Filter role="button" onMouseDown={handleFilterClearClick}>
												<FilterImage src={CloseBtn} alt="Close" />
											</Filter>
										</LastButtonContainer>
									
									</FiltersContainer>
								)}
								{filtersFromReferences?.length > 0 ? 
									<SelectDropdownContainer>
										<Dropdown data={options} updateDropdown={updateDropdown}/>
									</SelectDropdownContainer> : null }
							</FiltersWrapper>
							: null}
						{referencesToRender.length > 0 ? (
							<ReferencesWrapper data-element="references-wrapper">
								<ReferencesContainer data-element="references-container">
									{displayReferencesTop.map(reference => {
										const { id, filterCategories } = reference
										return <Reference 
											tags={(filterCategories || []).map(generateTagObjectFromString)}
											key={id} 
											referencesStyle={referencesStyle} 
											{...reference} />
									})}
								</ReferencesContainer>
								
								<ReferencesContainer data-element="references-container-bottom">
									{displayReferencesBottom.map(reference => {
										const { id, filterCategories } = reference
										return <Reference 
											tags={(filterCategories || []).map(generateTagObjectFromString)}
											key={id} 
											referencesStyle={referencesStyle} 
											{...reference} />
									})}
								</ReferencesContainer>
							</ReferencesWrapper>
							
						) : (
							<NoResultContainer data-element="no-result">
								<H5>No results</H5>
							</NoResultContainer>
						)}
						{hasMore && (
							<Link css={loadMoreLinkStyles} to={nextPageLink} onClick={handleMore}>
								<ButtonBase data-variant="primary" css={loadMoreStyles}>
								Load More
								</ButtonBase>
							</Link>
						)}
						{contentfulForm && !hideNewsletterForm ? (
							<ArticlesForm
								title="Free Brand Insights and Tips"
								description="Sign up for our Newsletter to receive free, insightful tips on all things brand!"
								{...contentfulForm}
							/>
						) : null}
						
					</ReferenceContainer>
				</Section>
			)}
		</div>
	)
}

export const query = graphql`
	fragment ReferencesSection on ContentfulReferencesSection {
		__typename
		id
		brandImages {
			...BrandImagesNew
		}
		brandImagesScrolling
		embedYoutubeId
		anchor
		featuredTitle {
			featuredTitle
		}
		featuredButtonText
		featuredStyle
		featuredSubtitle
		hideFiltersWrapper
		hideNewsletterForm
		featuredColorScheme {
			...ColorScheme
		}
		title {
			title
		}
		titleAlign
		description {
			description
		}
		referencesStyle
		customSpacing {
			internal {
				content
			}
		}
		searchInput
		featuredReference {
			__typename

			... on ContentfulPage {
				__typename
				id
				category
				categoryImage {
					...MediaReviewLogo
				}
				longTitle: title {
					title
				}
				description {
					description
				}
				slug
				heroImage {
					...MediaOriginal
				}
				referenceImage {
					...MediaOriginal
				}
				secondaryImage {
					...MediaOriginal
				}
			}
			... on ContentfulBlogPost {
				__typename
				id
				title
				description {
					description
				}
				slug
				heroImage {
					...MediaOriginal
				}
			}
			... on ContentfulKnowledgePost {
				__typename
				id
				slug
				title
				heroImage {
					...MediaOriginal
				}
				description {
					description
				}
			}
		}
		references {
			__typename
			... on ContentfulPage {
				__typename
				id
				category
				filterCategories
				categoryImage {
					...MediaReviewLogo
				}
				longTitle: title {
					title
				}
				description {
					description
				}
				slug
				heroImage {
					...MediaOriginal
				}
				referenceImage {
					...MediaOriginal
				}
				secondaryImage {
					...MediaOriginal
				}
				popular
				createdAt
				updatedAt
				referenceTitle
			}
			... on ContentfulBlogPost {
				__typename
				id
				title
				filterCategories
				description {
					description
				}
				slug
				heroImage {
					...MediaOriginal
				}
			}
		}
	}
`

export default React.memo(ReferencesSection)
